import React from "react"

export default function SocialMobile() {
    return (
        <div className="social fs-14 social--mobile-bottom">
            <div className="social__item">© 2023 ITSET WEB STUDIO</div>
            {/*
            <div className="social__item">
                <a href="https://www.facebook.com/itsetstudio" className="social__link" target="_blank">Facebook</a>&nbsp;|&nbsp;
                <a href="https://www.instagram.com/itsetstudio" className="social__link" target="_blank">Instagram</a>
            </div>
            */}
        </div>
    );
}