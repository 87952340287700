export const titlesEN = {
    "homepage": "Home | Itset.ee",
    "homepageDescription": "This website is so awesome that I really don't think you can handle how much awesome that is happening here.",
    "about": "About | Itset.ee",
    "aboutDescription": "This website is so awesome that I really don't think you can handle how much awesome that is happening here.",
    "works": "Works | Itset.ee",
    "worksDescription": "This website is so awesome that I really don't think you can handle how much awesome that is happening here.",
    "blog": "Blog | Itset.ee",
    "blogDescription": "This website is so awesome that I really don't think you can handle how much awesome that is happening here.",
    "careers": "Careers | Itset.ee",
    "careersDescription": "This website is so awesome that I really don't think you can handle how much awesome that is happening here.",
    "contact": "Contact | Itset.ee",
    "contactDescription": "This website is so awesome that I really don't think you can handle how much awesome that is happening here."
};
