export const en = {
    'cookie_header':
        `
        This website uses cookies
        `,
    'cookie_text':
        `
        We use cookies to personalise content and ads, to provide social media features and to analyse our traffic.
        `,
    'cookie_btn':
        `
        Accept
        `,


    'home_header':
        `
        We build <mark>identities</mark> and <mark>experiences</mark> to elevate and empower your <mark>business</mark>
        `,
    'home_view_more_works':
        `
        View more works
        `,
    'home_client_header':
        `
        <mark>Our</mark> clients
        `,
    'home_client_text':
        `
        Become one of the many happy and successful clients who chose us.
        `,
    'home_query_header':
        `
        Let’s build something <mark>great</mark> together
        `,
    'home_query_btn':
        `
        Send query
        `,


    'about_header':
        `
        About
        `,
    'about_slogan':
        `
        We create <mark>individual</mark> and <mark>creative</mark> sites that work to promote your <mark>business</mark>
        `,
    'about_story_header_1':
        `
        Who we are
        `,
    'about_story_text_1':
        `
        We are professionals who love our job immensely. When we come on board, you can be sure your project will be 
        fully completed from beginning to end. 
        Your website will start making a profit as soon as it is launched. What is more, if you find any shortcomings, 
        they will be corrected at exactly the agreed time.
        `,
    'about_story_header_2':
        `
        Our goal
        `,
    'about_story_text_2':
        `
        The design, creation, development and maintenance of websites increase your brand awareness as well as your 
        customer base, and they eventually ensure the efficiency and profitability of your business.
        `,
    'about_what_we_do_header':
        `
        What <mark>we do</mark>
        `,
    'about_what_we_do_subtitle_1':
        `
        Web design and development
        `,
    'about_what_we_do_text_1':
        `
        We work on a design that is pleasing to the eye and easy to work with. Each project we complete has its own 
        individual characteristics. Ergonomics, style, eye-catching accents, easy navigation and much more - you will 
        not be disappointed! With our experience, we can confidently guarantee the success of every project.
        `,
    'about_what_we_do_subtitle_2':
        `
        Website promotion
        `,
    'about_what_we_do_text_2':
        `
        We will help you to take leading positions in search engines for the main key queries related to the specifics of your business, and we ensure brand awareness.
        `,
    'about_what_we_do_subtitle_3':
        `
        Consulting
        `,
    'about_what_we_do_text_3':
        `
        We will advise on any issue related to website development, design and general issues. We will discuss 
        promotional and unknown features with you that lead to your success. Contact us - we are on hand to help you!
        `,

    // CONTACT & CONTACT-MESSAGE HEADER
    'contact_header':
        `
        Contact
        `,
    'contact_address':
        `
        Tallinn, Estonia
        `,
    'contact_form_header':
        `
        <mark>Fill this out</mark> so we can learn more about you and <mark>your needs</mark>
        `,
    'contact_form_name':
        `
        Your Name
        `,
    'contact_form_organization':
        `
        Organization Name
        `,
    'contact_form_phone':
        `
        Phone number
        `,
    'contact_form_email':
        `
        Email
        `,
    'contact_form_text':
        `
        Request text
        `,
    'contact_form_btn':
        `
        Send
        `,
    'contact_message_header':
        `
        Thank you
        `,
    'contact_message_subtitle':
        `
        We'll get back to you soon
        `,
    'contact_message_text':
        `
        Your email has been sent
        `,
    'contact_message_btn':
        `
        Send another message
        `,


    // WORDS
    'shareThisPage':
        `
        Share this page
        `,
};