export const titlesRU = {
    "homepage": "Главная | Itset.ee",
    "homepageDescription": "Этот сайт просто замечательный",
    "about": "О нас | Itset.ee",
    "aboutDescription": "Этот сайт просто замечательный",
    "works": "Itset | Works",
    "worksDescription": "Этот сайт просто замечательный",
    "blog": "Itset | Blog",
    "blogDescription": "Этот сайт просто замечательный",
    "careers": "Itset | Careers",
    "careersDescription": "Этот сайт просто замечательный",
    "contact": "Контакты | Itset.ee",
    "contactDescription": "Этот сайт просто замечательный"
};
