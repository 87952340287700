export const et = {
    'cookie_header':
        `
        See veebisait kasutab küpsiseid
        `,
    'cookie_text':
        `
        Kasutame küpsiseid sisu ja reklaamide isikupärastamiseks, sotsiaalmeedia funktsioonide pakkumiseks ning liikluse analüüsimiseks.
        `,
    'cookie_btn':
        `
        Nõustun
        `,


    'home_header':
        `
        Paneme <mark>tehnoloogia</mark> ja <mark>disaini</mark> toimima teie ettevõtte <mark>heaks</mark>
        `,
    'home_client_header':
        `
        <mark>Meie</mark> kliendid
        `,
    'home_client_text':
        `
        Hakka üheks neist õnnelikest ja edukatest klientidest, kes meid valivad
        `,
    'home_query_header':
        `
        Loome koos midagi <mark>suurepärast</mark>
        `,
    'home_query_btn':
        `
        Saada päring
        `,


    'about_header':
        `
        Meist
        `,
    'about_slogan':
        `
        Loome <mark>individuaalsed</mark> ja <mark>originaalsed</mark> veebilehed, mis aitavad edendada Teie <mark>äritegevust</mark>
        `,
    'about_story_header_1':
        `
        Kes me oleme
        `,
    'about_story_text_1':
        `
        Oleme professionaalid, kes armastavad oma tööd. Kui tegeleme teie projektiga, võite olla kindel, et see on 
        viidud lõpuni, veebileht hakkab teenima ka kasumit ja kõik puudused kõrvaldatakse täpselt kokkulepitud ajaks.
        `,
    'about_story_header_2':
        `
        Meie eesmärk
        `,
    'about_story_text_2':
        `
        Niisuguste veebilehtede arendamine, loomine, edendamine ja hooldamine, mis suurendavad teie brändi tuntust, 
        kasvatavad kliendibaasi ning teie äri efektiivsust ja kasumlikkust.
        `,
    'about_what_we_do_header':
        `
        Mida <mark>me teeme</mark>
        `,
    'about_what_we_do_subtitle_1':
        `
        Veebidisain ja -arendus
        `,
    'about_what_we_do_text_1':
        `
        Me töötame välja kujunduse, mida on silmal meeldiv vaadata ja millega on lihtne töötada. Igal meie teostatud 
        projekt on individuaalse eripäraga. Ergonoomika, stiil, pilkupüüdvad rõhuasetused, lihtne navigeerimine ja 
        palju muud – te jääte rahule. Meie kogemus võimaldab tagada igale projektile edu.
        `,
    'about_what_we_do_subtitle_2':
        `
        Veebilehtide edendamine
        `,
    'about_what_we_do_text_2':
        `
        Aitame teil saavutada tippkohad otsingusüsteemides vastavalt teie ettevõtte spetsiifikat puudutavatele 
        põhipäringutele ja tagame brändi tuntuse.
        `,
    'about_what_we_do_subtitle_3':
        `
        Nõustamine
        `,
    'about_what_we_do_text_3':
        `
        Nõustame kõigis veebilehtede arendamise, kujunduse ja üldiste aspektidega seotud küsimustes. Anname teavet 
        arengute ja teile seni tundmatute detailide kohta, mis viivad eduni. Võtke meiega ühendust – oleme valmis dialoogiks!
        `,

    // CONTACT & CONTACT-MESSAGE HEADER
    'contact_header':
        `
        Kontakt
        `,
    'contact_address':
        `
        Tallinn, Estonia
        `,
    'contact_form_header':
        `
        <mark>Täitke vorm</mark>, et saaksime rohkem teada teist ja <mark>teie vajadustest</mark>
        `,
    'contact_form_name':
        `
        Nimi
        `,
    'contact_form_organization':
        `
        Etevõtte
        `,
    'contact_form_phone':
        `
        Telefon
        `,
    'contact_form_email':
        `
        Email
        `,
    'contact_form_text':
        `
        Päringu sisu
        `,
    'contact_form_btn':
        `
        Saada
        `,
    'contact_message_header':
        `
        Täname Teid kirja eest
        `,
    'contact_message_subtitle':
        `
        Võtame Teiega varsti ühendust
        `,
    'contact_message_text':
        `
        Teie päring on saadetud
        `,
    'contact_message_btn':
        `
        Uus päring
        `,


    // WORDS
    'shareThisPage':
        `
        Share this page
        `,
};