import React, {useEffect, useState} from "react"
import {translation} from "../data/translations/languages";
import { useCookies } from 'react-cookie';

export default function Cookie({language}) {
    let t = translation[language];
    const [cookies, setCookie] = useCookies(['cookieAccepted']);
    const [cookieClass, setCookieClass] = useState("cookie max-w-636 hidden");

    function acceptCookie(e) {
        e.preventDefault();
        let d = new Date();
        d.setTime(d.getTime() + (365 * 24 *60 *60*1000));

        setCookie("cookieAccepted", 1, {path: "/", expires: d});

        let cookieBlock = document.querySelector('.cookie');
        cookieBlock.classList.add('hidden');
    }

    useEffect(() => {
        setCookieClass(getCookieClass());
    }, []);

    function getCookieClass() {
        if (cookies.cookieAccepted === '1') {
            return "cookie max-w-636 hidden"
        } else {
            return "cookie max-w-636";
        }
    }

    return (
        <div className={cookieClass}>
            <div className="cookie__header fs-18">{t.cookie_header}</div>
            <div className="cookie__text fs-14">{t.cookie_text}</div>
            <a href="#" className="btn" onClick={acceptCookie}>{t.cookie_btn}</a>
        </div>
    );
}