import React from "react"
import { Link } from "gatsby"
import {menu} from "../data/menu/menu";

export default function Footer({data}) {
    let language = data.pageContext.language;
    let currentPage = menu['ComponentIndex'];
    if(data.pageContext.currentMenuItem){
        currentPage = data.pageContext.currentMenuItem;
    }

    function currentLang(lang) {
        if(lang === language) return "lang__item lang__item--active";
        return "lang__item";
    }

    return (
        <footer className="footer left-col__footer">
            <div className="lang fs-18">
                <Link to={currentPage['en'].slug} className={currentLang('en')}>English</Link>
                <Link to={currentPage['et'].slug} className={currentLang('et')}>Eesti keeles</Link>
                <Link to={currentPage['ru'].slug} className={currentLang('ru')}>Русский</Link>
            </div>
            <div className="social footer__social fs-14">
                <div className="social__item">© 2023 ITSET WEB STUDIO</div>
                {/*
                <div className="social__item">
                    <a href="https://www.facebook.com/itsetstudio" className="social__link" target="_blank">Facebook</a>&nbsp;|&nbsp;
                    <a href="https://www.instagram.com/itsetstudio" className="social__link" target="_blank">Instagram</a>
                </div>
                */}
            </div>
        </footer>
    );
}