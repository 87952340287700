import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"

export default function LeftColumn({language, data}) {
    return (
        <div className="left-col">
            <Header language={language} data={data}/>
            <Footer data={data}/>
        </div>
    );
}