import React from "react"
import { Link } from "gatsby"
import {menu} from "../data/menu/menu";

function menuBtnClick() {
    let menuBtn = document.querySelector('.menu-btn');
    let page = document.querySelector('.page');

    if (menuBtn.classList.contains('menu-btn--clicked')) {
        menuBtn.classList.remove('menu-btn--clicked', 'close');
        page.classList.remove('page--mobile');
    } else {
        menuBtn.classList.add('menu-btn--clicked', 'close');
        page.classList.add('page--mobile');
    }
}

export default function Header({language, data}) {
    let menuItems = [];
    for (let key in menu) {
        let m = menu[key][language];
        if(data.pageContext.currentMenuItem && data.pageContext.currentMenuItem[language].slug === m.slug){
            menuItems.push(<li key={m.slug} className="navigation__item navigation__item--active"><Link to={m.slug}>{m.title}</Link></li>);
        } else {
            menuItems.push(<li key={m.slug} className="navigation__item"><Link to={m.slug}>{m.title}</Link></li>);
        }
    }

    return (
        <header className="header">
            <div className="logo">
                <Link to="/" className="logo__link rotate"/>
            </div>
            <div className="menu-btn" onClick={menuBtnClick}>
                <span className="menu-btn__content"/>
            </div>
            <ul className="navigation">
                {menuItems}
            </ul>
        </header>
    );
}