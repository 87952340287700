export const ru = {
    'cookie_header':
        `
        Наш сайт использует cookies
        `,
    'cookie_text':
        `
        Мы используем файлы cookie для персонализации контента и рекламы, предоставления функций социальных сетей и анализа нашего трафика.
        `,
    'cookie_btn':
        `
        Принять
        `,


    'home_header':
        `
        Мы заставим <mark>технологии</mark> и <mark>дизайн</mark> работать на ваш <mark>бизнес</mark>
        `,
    'home_client_header':
        `
        <mark>Наши</mark> клиенты
        `,
    'home_client_text':
        `
        Станьте одним из тех счастливых и успешных клиентов, которые выбрали нас.
        `,
    'home_query_header':
        `
        Создадим что-то <mark>прекрасное</mark> вместе 
        `,
    'home_query_btn':
        `
        Отправить запрос
        `,


    'about_header':
        `
        О нас
        `,
    'about_slogan':
        `
        Мы создаем <mark>индивидуальные</mark> и <mark>оригинальные</mark> сайты, которые будут работать на продвижение вашего <mark>бизнеса</mark>
        `,
    'about_story_header_1':
        `
        Кто мы такие
        `,
    'about_story_text_1':
        `
        Мы - профессионалы и очень любим свою работу. 
        Если мы беремся за ваш проект, вы можете быть уверены, что он будет доведен до конца, сайт будет не просто запущен, 
        но и начнет приносить прибыль, а любые недочеты будут исправлены в точно оговоренное время.
        `,
    'about_story_header_2':
        `
        Наша цель
        `,
    'about_story_text_2':
        `
        Разработка, создание, развитие и обслуживание веб-сайтов, которые повышают узнаваемость вашего бренда, увеличивают количество покупателей и, 
        в конечном итоге, обеспечивают эффективность и прибыльность вашего бизнеса. 
        `,
    'about_what_we_do_header':
        `
        Что <mark>мы делаем</mark>
        `,
    'about_what_we_do_subtitle_1':
        `
        Веб дизайн и разработка 
        `,
    'about_what_we_do_text_1':
        `
        Мы прорабатываем дизайн, приятный для глаз и удобный для работы. 
        Каждый проект, который мы делаем, обладает индивидуальными чертами. Эргономика, стиль, 
        эффектные акценты, удобная навигация и многое другое – вы останетесь довольны. Наш опыт позволяет гарантировать успех каждому проекту. 
        `,
    'about_what_we_do_subtitle_2':
        `
        Продвижение сайтов
        `,
    'about_what_we_do_text_2':
        `
        Мы поможем вам занять лидирующие позиции в поисковых системах по основным ключевым запросам, касающимся специфики вашей деятельности и 
        обеспечим узнаваемость бренда. 
        `,
    'about_what_we_do_subtitle_3':
        `
        Консультации
        `,
    'about_what_we_do_text_3':
        `
        Мы проконсультируем по любому вопросу, связанному с разработкой веб-сайтов, дизайном и общими моментами. 
        Расскажем о продвижении и неизвестных вам особенностях, которые приводят к успеху. Обращайтесь – мы готовы к диалогу!
        `,

    // CONTACT & CONTACT-MESSAGE HEADER
    'contact_header':
        `
        Контакты
        `,
    'contact_address':
        `
        Tallinn, Estonia
        `,
    'contact_form_header':
        `
        <mark>Заполните форму</mark>, тогда мы сможем больше узнать о вас и ваших <mark>потребностях</mark>
        `,
    'contact_form_name':
        `
        Имя
        `,
    'contact_form_organization':
        `
        Компания
        `,
    'contact_form_phone':
        `
        Телефон
        `,
    'contact_form_email':
        `
        Электронный адрес
        `,
    'contact_form_text':
        `
        Текст запроса
        `,
    'contact_form_btn':
        `
        Отправить
        `,
    'contact_message_header':
        `
        Спасибо за Ваше письмо
        `,
    'contact_message_subtitle':
        `
        Мы скоро свяжемся с вами
        `,
    'contact_message_text':
        `
        Ваше письмо отправлено
        `,
    'contact_message_btn':
        `
        Новый запрос 
        `,

    // WORDS
    'shareThisPage':
        `
        Share this page
        `,
};