export const titlesET = {
    "homepage": "Avaleht | Itset.ee",
    "homepageDescription": "See sait on lihtsalt imeline",
    "about": "Meist | Itset.ee",
    "aboutDescription": "See sait on lihtsalt imeline",
    "works": "Itset | Works",
    "worksDescription": "See sait on lihtsalt imeline",
    "blog": "Itset | Blog",
    "blogDescription": "See sait on lihtsalt imeline",
    "careers": "Itset | Careers",
    "careersDescription": "See sait on lihtsalt imeline",
    "contact": "Kontakt | Itset.ee",
    "contactDescription": "See sait on lihtsalt imeline"
};
