import React from "react";

export const menu = {
    'ComponentIndex':{
        'en':{
            'slug':"/",
            'title':"Home"
        },
        'ru':{
            'slug':"/ru",
            'title':"Главная"
        },
        'et':{
            'slug':"/et",
            'title':"Avaleht"
        },
    },
    'ComponentAbout':{
        'en':{
            'slug':"/about",
            'title':"About"
        },
        'ru':{
            'slug':"/ru/about",
            'title':"О нас"
        },
        'et':{
            'slug':"/et/about",
            'title':"Meist"
        },
    },
    'ComponentContact':{
        'en':{
            'slug':"/contact",
            'title':"Contact"
        },
        'ru':{
            'slug':"/ru/contact",
            'title':"Контакты"
        },
        'et':{
            'slug':"/et/contact",
            'title':"Kontakt"
        },
    }
};